<template>
  <div class="page-content">
    <div class="card">
      <div class="card-header">
        <b>{{ channel_obj.name }} Profile</b>
      </div>
      <b-card no-loby>
        <edit-whatsapp v-if="channel_type === 'whatsapp'" ref="whatsapp_component" />
        <edit-webchat v-if="channel_type === 'webchat'" ref="webchat_component" />
      </b-card>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  email, maxLength, url, helpers,
} from '@vuelidate/validators';
// import popupErrorMessages from '../../../library/popup-error-messages';
// import 'babel-polyfill';
// import ImageCrop from 'vue-image-crop-upload/upload-2.vue';
import channelsAPI from '../../../api/channels';
import EditWhatsapp from '../../components/channels/EditWhatsapp.vue';
import EditWebchat from '../../components/channels/EditWebchat.vue';

export default {
  name: 'ChannelProfile',
  metaInfo: {
    title: 'Channel Profile',
  },
  components: {
    // ImageCrop,
    EditWhatsapp,
    EditWebchat,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form_profile: {
        email: { email, maxLength: maxLength(128) },
        about: { maxLength: maxLength(256) },
        address: { maxLength: maxLength(256) },
        description: { maxLength: maxLength(512) },
        websites: {
          maxLength: maxLength(2),
          $each: helpers.forEach({
            url: {
              url,
              maxLength: maxLength(256),
            },
          }),
        },
      },
    };
  },
  data() {
    return {
      channel_type: '',
      channel_obj: {},
      channel_list: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
      loading: {
        update_profile: false,
      },
      form_profile: {
        about: '',
        address: '',
        description: '',
        email: '',
        websites: [],
        base64_profile: '',
      },
      selected_file_profile: null,
      showCropImage: false,
      loaderStack: 0,
      loader: null,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getChannelProfile();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    toggleShow() {
      this.showCropImage = !this.showCropImage;
    },
    // cropSuccess(imgDataUrl, field){
    cropSuccess(imgDataUrl) {
      console.log('-------- crop success --------');
      // this.imgDataUrl = imgDataUrl;
      this.form_profile.base64_profile = imgDataUrl;
    },
    async getChannelProfile() {
      await channelsAPI.getChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
      }).then(({ data }) => {
        const { channel_type } = data;
        this.channel_type = channel_type;
        if (channel_type === 'whatsapp') {
          this.$nextTick(() => {
            this.$refs.whatsapp_component.getChannelProfile();
          });
        } else if (channel_type === 'webchat') {
          this.$nextTick(() => {
            this.$refs.webchat_component.getChannelProfile();
          });
        }
        console.log(this.channel_type);
      }).catch((err) => console.log(err));
    },
  },
};
</script>
