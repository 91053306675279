<template>
  <b-card-body>
    <div class="row">
      <div class="col-12">
        <b-form @submit.prevent="updateProfile">
          <b-form-group label="Website Name">
            <el-input
              v-model="configuration.website_name"
              placeholder="Insert your website name"/>
          </b-form-group>
          <b-form-group label="Website Domain">
            <el-input
              v-model="configuration.website_domain"
              placeholder="Insert your website domain"/>
          </b-form-group>
          <b-form-group label="Widget Color">
            <el-color-picker v-model="configuration.widget_color" required ></el-color-picker>
          </b-form-group>
          <b-form-group label="Welcome Heading">
            <el-input
              v-model="configuration.welcome_heading"
              placeholder="Insert your welcome heading"/>
          </b-form-group>
          <b-form-group label="Welcome Tagline">
            <el-input
              v-model="configuration.welcome_tagline"
              placeholder="Insert your welcome tagline"/>
          </b-form-group>
          <b-form-group label="Access Token">
            <el-input
              v-model="access_token"
              placeholder="Your access token" disabled/>
          </b-form-group>
          <b-form-group label="Script Embed">
            <div class="bd-example text-sm text-monospace" style="position: relative;">
              <el-popover
                placement="top"
                trigger="hover"
                style="position: absolute; top: 10px; right: 10px">
                <p style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all; font-size: 12px;">Copy to clipboard, paste inside <strong>&lt;script&gt;&lt;/script&gt;</strong> tag</p>
                <button slot="reference" type="button" class="btn btn-outline-primary btn-xs" @click="copyScriptEmbed">Copy</button>
              </el-popover>
              <pre id="code-script-embed" class="rounded" style="font-size: 12px">{{ script_embed_webchat_sdk }}</pre>
            </div>
          </b-form-group>
          <div class="d-flex float-right">
            <el-button @click="updateProfile" class="btn mr-2 btn-primary">{{ $t('general.submit') }}</el-button>
            <el-button @click="backChannelList" class="btn btn-secondary">{{ $t('general.cancel') }}</el-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-card-body>
</template>
<script>
import channelsAPI from '../../../api/channels';

export default {
  name: 'EditWebchatProfile',
  data() {
    return {
      profile: '',
      configuration: {
        website_name: '',
        website_domain: '',
        widget_color: '',
        welcome_heading: '',
        welcome_tagline: '',
      },
      access_token: '',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    script_embed_webchat_sdk() {
      let script_embed = `(function(d,t) {
  localStorage.setItem("makna-webchat-sdk-api-url", "[sdk-api-url]");
  localStorage.setItem("makna-webchat-sdk-token", "[sdk-token]");
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0],w = d.createElement('div');
  w.setAttribute("id", "makna-sdk");
  g.type="module";
  g.crossorigin="anonymous";
  g.src="[sdk-cdn-url]";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(w,s);
  s.parentNode.insertBefore(g,s);
})(document,"script");`;
      script_embed = script_embed.replace('[sdk-api-url]', (process.env.VUE_APP_WEBCHAT_SDK_API_URL || ''));
      script_embed = script_embed.replace('[sdk-token]', (this.access_token || ''));
      script_embed = script_embed.replace('[sdk-cdn-url]', (process.env.VUE_APP_WEBCHAT_SDK_CDN_URL || ''));
      return script_embed;
    },
  },
  watch: {
    script_embed_webchat_sdk() {},
  },
  mounted() {
    this.getChannelProfile();
  },
  methods: {
    async updateProfile() {
      await channelsAPI.updateChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        data: {
          type: 'webchat',
          configuration: this.configuration,
        },
      }).then(() => {
        this.$message({
          type: 'success',
          message: 'Profile updated.',
        });
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Failed to update profile.',
        });
      });
    },
    async getChannelProfile() {
      await channelsAPI.getChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
      }).then(({ data }) => {
        const { profile } = data;
        this.configuration = profile;
        this.access_token = profile.access_token ?? '';
      }).catch((err) => console.log(err));
    },
    backChannelList() {
      this.$router.push({
        name: 'Channels',
      });
    },
    copyScriptEmbed() {
      const code_script = document.getElementById('code-script-embed');
      navigator.clipboard.writeText(code_script.innerHTML);
      this.$message({
        message: 'Script copied!',
        type: 'success',
      });
    },
  },
};
</script>
